import { getRichContentFeatures } from '@wix/rich-content-features';
import { getCaptchaSettings } from '../../selectors/forum-data-selectors';
import { hasSuspiciousFields } from '../../../common/services/captcha-helpers';
import { RootState } from '../../types/store-types';
import { RichContent } from '@wix/ricos-common';

export const shouldShowCaptcha = (state: RootState, commentContent: RichContent) => {
  const {
    enableOnCommentCreation: isCaptchaOnCommentEnabled,
    enableOnSuspiciousActivity: isCaptchaOnSuspiciousEnabled,
  } = getCaptchaSettings(state);

  let isCaptchaForContent = false;

  if (isCaptchaOnSuspiciousEnabled) {
    const contentFeatures = getRichContentFeatures(commentContent);

    isCaptchaForContent = hasSuspiciousFields(contentFeatures);
  }

  return isCaptchaForContent || isCaptchaOnCommentEnabled;
};
